import React from 'react';
import CVPdf from './documents/ItiArmpaluCV.pdf';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { getPortfolio } from "./data";
import { handleErrors, jsonHeader } from './utils/fetchHelper';

class Portfolio extends React.Component {
  constructor(props) {
    super(props);
    this.portfolioRef = React.createRef();
    this.state = {
      name: '',
      email: '',
      message: '',
      success: '',
      error: ''
    }
  }

  executeScroll = () => {
    this.portfolioRef.current?.scrollIntoView({behavior: 'smooth'});
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  submitContactForm = (e) => {
    e.preventDefault();
    // alert('A name was submitted: ' + this.state.name);
    
    fetch('https://55i9cznoz8.execute-api.us-east-1.amazonaws.com/Production/contact-form', jsonHeader({
      method: 'POST',
      body: JSON.stringify({
          name: this.state.name,
          email: this.state.email,
          message: this.state.message
      })
    }))
      .then(handleErrors)
      .then(data => {
        this.setState({
          name: '',
          email: '',
          message: ''
        })

        if (data === "Email sent") {
          this.setState({
            success: "Submitted!"
          })
          } else {
            this.setState({
              success: "Something went wrong. Please try again."
            })
          }
          setTimeout(() => {
            this.setState({
              success: ""
            })
          }, 5000);
        })
      .catch(error => {
        this.setState({
          error: 'Error occured'
        })
      })
  }

  render () {
    const { name, email, message, success, error } = this.state;
    let portfolioData = getPortfolio();

    return (
      <React.Fragment>

      {/* Hero section */}
      <div className="container-fluid">
        <div className="hero-wrap">
          <div className="row no-gutters align-items-center">
            <div 
              className="col-12 col-md-6">
              <div className="hero-text-wrap hero">
                <div>
                  <h1
                    className="mt-5"
                    data-aos="fade-up"
                    data-aos-delay="100">
                      Hi, my name is
                  </h1>
                </div>
                <div>
                  <h2
                    className='big-heading'
                    data-aos="fade-up"
                    data-aos-delay="200">
                      Iti Armpalu.
                  </h2>
                </div>
                <div>
                  <h3
                    className='big-heading'
                    data-aos="fade-up"
                    data-aos-delay="300">
                      I build things for the web.
                  </h3>
                </div>
                <div>
                  <p
                    data-aos="fade-up"
                    data-aos-delay="400">
                      I'm a junior full-stack web developer. Scroll down to see my 
                      <button 
                        className='btn btn-portfolio hover-underline-animation shadow-none'
                        type='button'
                        onClick={this.executeScroll}>
                          portfolio
                      </button>.
                  </p>
                </div>
                <div 
                  className="social-wrap d-flex"
                  data-aos="fade-up"
                  data-aos-delay="500">
                    <a 
                      className="d-none d-sm-block btn btn-download-cv"
                      href = {CVPdf} 
                      target = "_blank"
                      rel="noopener noreferrer">
                        View & download CV
                    </a>
                    <a 
                      className="d-block d-sm-none btn btn-download-cv"
                      href = {CVPdf} 
                      target = "_blank"
                      rel="noopener noreferrer">
                        View CV
                    </a>
                    <div 
                      className="contact-social-icons d-flex justify-content-start">
                        <a 
                          href="https://github.com/iti-armpalu" 
                          target="_blank" 
                          rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faGithub} className="icon-social"/>
                        </a>
                        <a 
                          href="https://www.linkedin.com/in/itiarmpalu/" 
                          target="_blank" 
                          rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedinIn} className="icon-social"/>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div 
                className="aspectRatioBox"
                data-aos="fade-up"
                data-aos-delay="100"
                style={{backgroundImage:`url(${require("./images/woman-coding.png")})`}}
                >
              </div>
            </div>
          </div>
        </div>
      </div>

        {/* Portfolio section */}
        <div className='portfolio-bg'>
          <div className="container-fluid">
            <div 
              className="portfolio-wrap" 
              id="portfolio"
              ref={this.portfolioRef}>
              <div className="section-title">
                <h2>Portfolio<span>.</span></h2>
                <p>Featured below are some of my latest work.</p>
                <p></p>
              </div>

              <div className='row no-gutters custom-gutter'>
              {portfolioData.map((portfolio) => (
                <div
                  key={portfolio.id}
                  className="col-12 col-md-6 col-xl-3">
                    <a 
                      href={portfolio.url}
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="d-block"
                      data-aos="fade-up"
                      data-aos-delay={portfolio.dataAosDelay}>
                        <div className='article-card'>
                          <div 
                            className='aspectRatioBox-portfolio'
                            style={{backgroundImage:`url(${portfolio.image})`}}>
                          </div>
                          <div className='article-content'>
                            <p className='card-category'>
                              {portfolio.languages}
                            </p>
                            <h3 
                              className='card-title'>
                                {portfolio.title}
                            </h3>
                            <p className='card-excerpt'>
                              {portfolio.description}
                            </p>
                          </div>
                        </div>
                    </a>
                </div>
              ))}
              </div>
            </div>
          </div>
        </div>

        {/* About section */}
        <div 
          className="container-fluid"
          id="about">
          <div 
            className="about-wrap" 
            data-aos="fade-up">
            <div className="section-title">
              <h2>About<span>.</span></h2>
              <p>Let me introduce myself.</p>
            </div>
            <div className="row align-items-center">
              <div className="col-12 col-lg-4">
                <div className="about-intro">
                  <h5>
                    Hello! <span>I'm Iti Armpalu</span>
                  </h5>
                  <h6>
                    Full-Stack Web Developer
                  </h6>
                  <p>
                    I have recently switched my career from FMCG and tech marketing to software development. This has been the best decision I have ever made and I have truly found a career that excites and motivates me. I'm also a big fan of learning, and programming provides endless growth opportunities. It's exciting and terrifying at the same time how much I don't know yet, but we all have to start from somewhere and I'm ready to code and grow.
                  </p>
                  <a
                    href='https://www.altcademy.com/stories/@itiarmpalu'
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className='read-interview-link hover-underline-animation'>
                      Read my student story
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div 
                  className="aspectRatioBox-image about-image"
                  style={{backgroundImage:`url(${require("./images/iti-bio-pic.png")})`}}>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="about-skills">
                  <div>
                    <p className='skill-title'>
                      Front-end
                    </p>
                    <div className='d-flex flex-wrap skill-list'>
                      <p>HTML</p>
                      <p>CSS</p>
                      <p>Javascript</p>
                      <p>React</p>
                      <p>Angular</p>
                      <p>Bootstrap</p>
                      <p>Material UI</p>
                    </div>
                  </div>
                  <div className="skill">
                    <p  className='skill-title'>
                      Back-end
                    </p>
                    <div className='d-flex flex-wrap skill-list'>
                      <p>Ruby on Rails</p>
                    </div>
                  </div>
                  <div className="skill">
                    <p className='skill-title'>
                      Database
                    </p>
                    <div className='d-flex flex-wrap skill-list'>
                      <p>PostgreSQL</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      

        {/* Contact section */}
        <div className='contact-bg'>
          <div className="container-fluid">
            <div 
              className="contact-wrap" 
              id="contact"
              data-aos="fade-up">
          <div className="section-title">
            <h2>Contact<span>.</span></h2>
            <p>
              I'm always open to discussing work or partnerships. Let's connect!
              </p>
          </div>
          <div className="row contact-inner rounded">

            <div className="col-12 col-md-6 col-lg-4 contact-column">
              <div className="contact rounded">
                <h5>
                  <b>LOCATION</b>
                  </h5>
                <p>
                  Currently based in London, United Kingdom.
                </p>
                <a 
                  href="https://goo.gl/maps/VCdpoMsQEWuWofyp7" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="d-block">
                    <div 
                      className="aspectRatioBox-location rounded"
                      style={{backgroundImage:`url(${require("./images/UK.png")})`}}>
                  </div>
                </a>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 contact-column">
              <div className="contact rounded">
                <h5>
                  <b>GET IN TOUCH</b>
                </h5>
                <div className="contact-email mt-5">
                  <h6>
                    Email me:
                  </h6>
                  <p>
                    iti.armpalu@gmail.com
                  </p>
                </div>
                <div className="contact-social mt-4">
                  <h6>
                    Connect with me:
                  </h6>
                  <div 
                    className="contact-social-icons d-flex justify-content-center mt-2">
                      <a 
                        href="https://github.com/iti-armpalu" 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faGithub} className="icon-social"/>
                      </a>
                      <a 
                        href="https://www.linkedin.com/in/itiarmpalu/" 
                        target="_blank" 
                        rel="noopener noreferrer">
                          <FontAwesomeIcon icon={faLinkedinIn} className="icon-social"/>
                      </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 contact-column">
              <div className="contact rounded">
                <h5>
                  <b>SEND A MESSAGE</b>
                  </h5>
                <form 
                  className="send-message"
                  onSubmit={this.submitContactForm}>
                    <div className="input-group send-message-field mb-3">
                      <input 
                        type="text" 
                        className="form-contol 
                        form-control-sm" 
                        placeholder="Name" 
                        name="name" 
                        value={name} 
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group send-message-field mb-3">
                      <input 
                        type="email" 
                        className="form-contol form-control-sm" 
                        placeholder="Email" 
                        name="email" 
                        value={email} 
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="input-group send-message-field mb-3">
                      <textarea 
                        className="form-contol 
                        form-control-sm" 
                        placeholder="Message"
                        name="message" 
                        value={message} onChange={this.handleChange}>
                        
                      </textarea>
                    </div>
                  <button 
                    type="submit" 
                    className="btn send-message-button">
                      Send message
                  </button>
                  {success && <p className="text-success mt-2">{success}</p>}
                  {error && <p className="text-danger mt-2">{error}</p>}
                </form>
              </div>
            </div>

          </div>
            </div>
          </div> 
        </div>
      </React.Fragment>
    )
  }
}

export default Portfolio;