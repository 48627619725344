let portfolioData = [
  {
    id: 1,
    title: "Design Tokens Generator",
    description: "A simple design token web application built in Angular to practice Material UI and Style Dictionary. Includes Color Picker library to set design token colors.",
    languages: "html · css · material ui · angular",
    image: require('./images/design-tokens-generator.png'),
    url: "https://phenomenal-liger-cc0bcb.netlify.app",
    dataAosDelay: "100",
  },
  {
    id: 2,
    title: "Recipe Book App",
    description: "A simple recipe book built in Angular front-end framework and Firebase to fetch Http requests, authenticate user and save data. User is able to sign up and login, fetch all the recipes, add, view, edit and delete a recipe, add ingredients to the shopping list.",
    languages: "html · css · bootstrap · angular · firebase database",
    image: require('./images/recipe-book.png'),
    url: "https://precious-biscochitos-2ebecf.netlify.app",
    dataAosDelay: "200",
  },
  {
    id: 3,
    title: "Food Delivery App",
    description: "A full stack food delivery app which was built as a capstone project and combines everything learnt throughout the modules. User can browse and filter restaurants, sign up and log in, add items to the basket, amend and delete items in the basket, and make a Stripe payment.",
    languages: "html · css · bootstrap · react · ruby on rails · postgres via render",
    image: require('./images/super-eats.png'),
    url: "https://fs-super-eats.onrender.com",
    dataAosDelay: "300",

  },
  {
    id: 4,
    title: "Airbnb Clone",
    description: "A full stack Airbnb application that is built in Rails, with webpacker added for React front-end views. User can browse a list of available properties, sign up and log in, book a property with check in and check out dates and make a payment via Stripe payment gateway.",
    languages: "html · css · bootstrap · react · ruby on rails · postgres via render",
    image: require('./images/airbnb-clone-full-stack.png'),
    url: "https://fs-airbnb-clone.onrender.com",
    dataAosDelay: "400",
  },
  {
    id: 5,
    title: "Twitter Clone",
    description: "A full stack Twitter web clone using Ruby on Rails and React frameworks. Project requirements: sign up and login pages along with user authentication, feed with the latest tweets, user feed, user can post and delete a tweet, user can log out.",
    languages: "html · css · bootstrap · react · ruby on rails · postgres via render",
    image: require('./images/twitter-clone-full-stack.png'),
    url: "https://fs-twitter-clone.onrender.com",
    dataAosDelay: "100",
  },
  {
    id: 6,
    title: "To-do List App",
    description: " A full stack application using Ruby on Rails for CRUD API endpoints and jQuery for Ajax requests and DOM manipulation. User is able to add, complete and delete a task, and filter en all, active and completed tasks.",
    languages: "html · css · bootstrap · jQuery · ruby on rails",
    image: require('./images/to-do-list.png'),
    url: "https://iti-full-stack-todolist.herokuapp.com/",
    dataAosDelay: "200",
  },
  {
    id: 7,
    title: "Currency Converter App",
    description: "A currency exchange rates website using React. The goal was to practice React framework and learn about development cycle - wire framing responsive user interfaces, planning React component structure, developing the app, and deploying to production.",
    languages: "html · css · bootstrap · react",
    image: require('./images/currency-converter.png'),
    url: "https://nimble-melba-a2c5fd.netlify.app/",
    dataAosDelay: "300",
  },
  {
    id: 8,
    title: "10 Seconds Math Game",
    description: "An interactive web game using JavaScript and jQuery, where the user needs to input the correct answer to a basic arithmetic equation. The game involved practicing functions, interval, time, event listeners and DOM manipulation.",
    languages: "html · css · bootstrap · jQuery",
    image: require('./images/10-seconds-math-game.png'),
    url: "https://lucid-galileo-42216f.netlify.app/",
    dataAosDelay: "400",
  },
  {
    id: 9,
    title: "Simple Shopping Cart",
    description: "The objective was to create a functioning shopping cart using JavaScript and jQuery during Dynamic Website Development module. Project criteria: calculate and display the total price and sub total price of each item, allow user to add and delete and item.",
    languages: "html · css · bootstrap · jQuery",
    image: require('./images/shopping-cart.png'),
    url: "https://mystifying-shannon-cf2954.netlify.app/",
    dataAosDelay: "100",
  },
  {
    id: 10,
    title: "Airbnb Newsroom Clone",
    description: "A simple Airbnb Newsroom landing page clone. This project was done during bootcamp's HTML and CSS module. The goal was to use Bootstrap elements throughout the project to get familiar with the framework.",
    languages: "html · css · bootstrap",
    image: require('./images/airbnb-press-clone.png'),
    url: "https://clever-ardinghelli-774b69.netlify.app",
    dataAosDelay: "200",
  },

  {
    id: 11,
    title: "Newsweek Website Clone",
    description: "A simple Newsweek website landing page clone. This project was done during bootcamp's HTML and CSS module to learn and practice front-end development.",
    languages: "html · css · bootstrap",
    image: require('./images/newsweek-clone.png'),
    url: "https://wonderful-babbage-769b00.netlify.app/",
    dataAosDelay: "300",
  },
  {
    id: 12,
    title: "Apple Website Clone",
    description: "A simple Apple website landing page clone. One of the first bootcamp projects done during HTML and CSS learning module.",
    languages: "html · css",
    image: require('./images/apple-clone.png'),
    url: "https://lighthearted-starship-e3c1aa.netlify.app/",
    dataAosDelay: "400",
  }
];

export function getPortfolio() {
  return portfolioData;
}