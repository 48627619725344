import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-regular-svg-icons'
import { Link } from 'react-router-dom';


const Layout = (props) => {

  // Scroll to Portfolio section
  const portfolioScroll = () => {
    const section = document.querySelector( '#portfolio' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  // Scroll to About section
  const aboutScroll = () => {
    const section = document.querySelector( '#about' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  // Scroll to Contact section
  const contactScroll = () => {
    const section = document.querySelector( '#contact' );
    section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
  };

  return (
    // A common pattern in React is for a component to return multiple elements. Fragments let you group a list of children without adding extra nodes to the DOM.
    <React.Fragment>
      <div className="background">

        {/* Navbar */}
        <nav className="navbar navbar-expand-md" id="navbar">

          {/* --- Navbar md and above */}
          <div className="d-none d-md-flex flex-grow-1 justify-content-between" id="mdNavbar">
            <Link 
              className="navbar-brand navbar-title"
              to="/">
                ITI ARMPALU
                <span className="navbar-title-portfolio">
                  portfolio
                </span>
            </Link>
            <div className='d-flex'>
              <button 
                type="button" 
                className="btn nav-link hover-underline-animation px-3 me-2"
                data-aos="fade-down"
                data-aos-delay="100"
                onClick={portfolioScroll}>
                  Portfolio
              </button>
              <button 
                type="button" 
                className="btn nav-link hover-underline-animation px-3 me-2"
                data-aos="fade-down"
                data-aos-delay="200"
                onClick={aboutScroll}>
                  About
              </button>
              <button 
                type="button" 
                className="btn nav-link hover-underline-animation px-3"
                data-aos="fade-down"
                data-aos-delay="300"
                onClick={contactScroll}>
                  Contact
              </button>
            </div>
          </div>

          {/* --- Navbar xs and sm */}
          <div className="d-flex d-md-none flex-grow-1 justify-content-center" id="xsNavbar">
            <Link 
                className="navbar-brand navbar-title"
                to="/">
                  ITI ARMPALU
                  <span className="navbar-title-portfolio">
                    portfolio
                  </span>
              </Link>
          </div>

          {/* --- Navbar - collapsed menu */}
          <div className="collapse navbar-collapse" id="navbarMenu">
            <ul className="list-unstyled">
              <li className="xsMenu-listItem">
                {/* <a href="#portfolio">Portfolio</a> */}
              </li>
              <li className="xsMenu-listItem">
                {/* <a href="#about">About</a> */}
              </li>
              <li className="xsMenu-listItem">
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </div>
        </nav>
      
        <div>
          {props.children}
        </div>

        {/* Footer section */}
        <div className='footer-bg'>
          <footer className="container-fluid">
            <div className="row no-gutters justify-content-between align-items-center footer-inner">
              <div className="col-auto">
                <span>
                  © 2022 Iti Armpalu. All rights reserved.
                </span>
              </div>
              <div className="col-auto">
                <button
                  className='btn'
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                  >
                  <FontAwesomeIcon icon={faArrowAltCircleUp}/>
                </button>
              </div>
            </div>
          </footer>     
        </div>
        
      </div>
    </React.Fragment>
  )
}

export default Layout;